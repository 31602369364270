import { Component } from '@angular/core';
import {Icon} from "./icons/icon.interface";
import {AngularFirestore} from "@angular/fire/firestore";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  email: string = '';
  formHasBeenSubmittedSuccessfully: boolean = false;
  error: boolean = false;

  icons: Icon[] = [
    {
      name: 'Instagram',
      url: 'http://instagram.com/loungemade',
      img: '../../../assets/ig.svg'
    },
    {
      name: 'Facebook',
      url: 'http://www.facebook.com/LoungeMade-114146843514827/',
      img: '../../../assets/fb.svg'
    },
    {
      name: 'Email',
      url: 'mailto:Loungemadeclothing@gmail.com',
      img: '../../../assets/email2.png'
    },
    {
      name: 'Twitter',
      url: 'http://twitter.com/loungemade',
      img: '../../../assets/twitter.svg'
    }
  ];

  constructor(
    private db: AngularFirestore
  ) {

  }

  subscribe(): void {
    this.db.collection('emails').add({
        email: this.email
      }
    ).then(
      res => {
        this.formHasBeenSubmittedSuccessfully = true;
      },
      err => {
        this.error = true;
      }
    )
  }
}
